import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      notfoundImage: file(relativePath: { eq: "404background.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const {notfoundImage} = data
  return (
    <Layout>
      <SEO title="404: Not found" />
        <BackgroundImage
            Tag="div"
            className="h-notfound"
            fluid={notfoundImage.childImageSharp.fluid}
            backgroundColor={`#040e18`}
          >
        <section className="container mx-auto text-center py-24">
          <h1 className="font-serif text-green-800 font-bold text-5xl">Page Not Found</h1>
          <p className="text-3xl">The page that you are looking for no longer exists or has moved</p>
        </section>
      </BackgroundImage>

    </Layout>
  )
  }

export default NotFoundPage
